import {addActivityToClassroom, downloadWorksheetHandler, initWorksheetDownload} from "../utils";
import {WEBSITE_CUSTOM_EVENTS, WORKSHEET_ACTION_TYPES} from "../config";

$(document).ready(() => {
  const $page = $('[data-selector="free-worksheet-page"]');
  if (!$page.length) return;

  ga('send', 'event', 'New-sign-up-welcome', 'New-sign-up-welcome-load', 'Load');

  const downloadWorksheetBtn = '[data-selector="download-worksheet"]';
  const assignToClassroom = '[data-selector="free-worksheet-assign-to-classroom"]';
  const goToWebCheckItemIconSelector = '[data-selector="free-worksheet-web-check"]';

  document
    .addEventListener(WEBSITE_CUSTOM_EVENTS.DOWNLOAD_WORKSHEET, (e) => {
      const worksheetId = e.detail.worksheetId;
      const $worksheetItem = $(`${downloadWorksheetBtn}[data-id="${worksheetId}"]`);
      const pdfLink = $worksheetItem.attr('data-download-href');
      initWorksheetDownload(pdfLink);
    }, false);

  $(document)
    .on('click', downloadWorksheetBtn, (e) => {
      e.preventDefault();

      downloadWorksheetHandler(e, WORKSHEET_ACTION_TYPES.DOWNLOAD);
    })
    .on('click', assignToClassroom, function (e) {
      const $btn = $(e.currentTarget);
      const gradeId = $btn.attr('data-grade-id');
      const categorySlug = $btn.attr('data-category-slug');
      const activityId = $btn.attr('data-activity-id');
      const activityContentType = $btn.attr('data-activity-content-type');
      const utm = $btn.attr('data-utm');

      addActivityToClassroom({
        activities: [{categorySlug, gradeId, activityId, activityContentType}],
        utm
      });
    })
    .on('click', goToWebCheckItemIconSelector, function (e) {
      e.preventDefault();
      downloadWorksheetHandler(e, WORKSHEET_ACTION_TYPES.COMPLETE_ONLINE);
    });
});
