(function() {
  window.adjustVideo = function () {
    $('.youtube-embed').each(function () {
      const $embed = $(this);
      const width = $embed.width();
      $embed.css('height', width * 9 / 16);
      $embed.css('max-height', 1070);
      $embed.css('min-height', 140);
      $embed.css('min-width', 140);
    });
  };

  $(window).on('resize', window.adjustVideo);
  window.adjustVideo();
})();
