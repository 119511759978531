
$(document).ready( () => {
  const sliderImg = '[data-selector="app-features-slider-img"]';
  const appDetailPreviewModal = '[data-selector="app-detail-img-modal"]';
  const $appDetailPreviewModal = $('[data-selector="app-detail-img-modal"]');
  const slider = '[data-selector="app-features-slider"]';
  const appDetailSliderItem = '[data-selector="app-features-slider-item"]';
  const $appDetailModalSlider = $('[data-selector="app-detail-modal-slider"]');
  const $modalSliderLoader = $('[data-selector="modal-slider-loader"]');

  let clickedSlickIndex;
  let clickedImageSrc;

  const initModalSlider = () => {
    $appDetailModalSlider
      .on('init', () => {
        setClickedSlickIndex();
      })
      .on('afterChange', () => {
        $modalSliderLoader.hide();
        $appDetailModalSlider.css({opacity: 1});
      });

    $appDetailModalSlider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        swipeToSlide: true,
    });
  };

  const setClickedSlickIndex = () => {
    $appDetailModalSlider.find('li').each(function() {
        const $image = $(this).find(sliderImg);
        if ($image.attr('src') === clickedImageSrc) {
          clickedSlickIndex = $(this).attr('data-slick-index');
          return false;
        }
    });
  };

  $(document)
    .on('click', appDetailSliderItem, function() {
      const $currentSlider = $(this).closest(slider);
      if ($currentSlider.hasClass('_videos')) return;

      const $sliderContent = $(this).closest('.slick-track').children().clone();
      $sliderContent.each(function() {
        const imgSrc = $(this).find(sliderImg).attr('src');
        if (!($appDetailModalSlider.find(`[src='${imgSrc}']`)).length) {
          $appDetailModalSlider.append('<li>' + $(this).html() + '</li>');
        }
      });

      clickedImageSrc = $(this).find(sliderImg).attr('src');

      $modalSliderLoader.show();
      $appDetailModalSlider.css({opacity: 0});
      $appDetailPreviewModal.modal('show');
    })
    .on('shown.bs.modal',  appDetailPreviewModal, function() {
      initModalSlider();
      $appDetailModalSlider.slick('slickGoTo', clickedSlickIndex);
    })
    .on('hidden.bs.modal', appDetailPreviewModal, function() {
      $('.modal-slider').slick('unslick');
      $appDetailModalSlider.empty();
    });
});
