$(document).ready(() => {
  const $appDetails = $('[data-selector="app-details"]');
  if (!$appDetails.length) return;

  const $appDetailsSlider = $('[data-selector="app-detail-slider"]');
  const $appDetailsGames = $('[data-selector="app-details-games"]');
  const $appDetailsVideos = $('[data-selector="app-details-videos"]');
  const $appDetailsWorksheets = $('[data-selector="app-details-worksheets"]');

  const initSlider = () => {
    $appDetailsSlider.on('init', () => {
      $appDetailsSlider.show();
    });

    $appDetailsSlider.not('.slick-initialized').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      swipeToSlide: true,
      responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }]
    });

    $appDetailsVideos.not('.slick-initialized').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [{
        settings: {
          slidesToShow: 4
        }
      }, {
        breakpoint: 991,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }]
    });

    $appDetailsWorksheets.not('.slick-initialized').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 4
        }
      },{
        breakpoint: 991,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }]
    });

    $appDetailsGames.not('.slick-initialized').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 4
        }
      },{
        breakpoint: 991,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }]
    });


  };

  initSlider();
});
