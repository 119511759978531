import {
  scrollToTop,
  scrollToHashedElement
} from '../utils';

$(document).ready( () => {
  // app-details tabs
  const tabContainerSelector = '[data-selector="app-detail-tab-container"]';
  const tabContentSelector = '[data-selector="app-detail-tab-content"]';
  const featuresInputsSelector = `${tabContainerSelector} input[name=features]`;
  const gradesInputsSelector = `${tabContainerSelector} input[name=grade]`;
  const checkedFeatureInputSelector = `${featuresInputsSelector}:checked`;
  const checkedGradeInputSelector = `${gradesInputsSelector}:checked`;
  const tabContentAttr = 'data-tab-content';

  const goToCheckoutBtnSelector = '[data-selector="app-detail-go-to-checkout-btn"]';
  const $goToCheckoutSliderBtn  = $('[data-selector="go-to-checkout-slider-btn"]');
  const goToTopBtnSelector = '[data-selector="app-detail-go-to-top-btn"]';

  const subscriptionPlansSliderHash = '#subscription-plans-slider';
  const $subscriptionSliderSection = $('[data-selector="subscription-slider-section"]');
  const $planInput = $('[data-selector="subscription-plan-slider-input"]');
  const offerPeriodPlanAttr = 'data-offer-period';

  const $tab = $('[data-selector="subscription-plans-tab"]');
  const tabPeriodAttr = 'data-tab-period';
  const PREFERRED_PLAN_PERIOD = 'yearly';

  // app features slider
  const $featureSliderLoader = $('[data-selector="app-features-slider-loader"]');
  const $featureSlider = $('[data-selector="app-features-slider"]');
  const $featureSliderContainer = $('[data-selector="app-features-slider-container"]');

  const $defaultSliderSettings = {
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      swipeToSlide: true,
      adaptiveHeight: true,
      responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },{
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },{
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }]
    };

  const $worksheetSliderSettings = {
      slidesToShow: 7,
      slidesToScroll: 1,
      autoplay: true,
      swipeToSlide: true,
      responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 5
        }
      },{
        breakpoint: 768,
        settings: {
          slidesToShow: 4
        }
      },{
        breakpoint: 700,
        settings: {
          slidesToShow: 3
        }
      },{
        breakpoint: 480,
        settings: {
          slidesToShow: 2
        }
      }]
    };

  const initFeatureSlider = () => {
    $featureSlider.on('init', () => {
      const $selectedFeatureInput = $(checkedFeatureInputSelector);
      const selectedFeatureTab = $selectedFeatureInput.attr('id');
      const $selectedTabContainer = $selectedFeatureInput.closest(tabContainerSelector);
      const $featureSlider = $selectedTabContainer.find(tabContentSelector);
      const $relatedFeatureSlider = $featureSlider
        .filter(`[${tabContentAttr}=${selectedFeatureTab}]`);
      $featureSliderLoader.hide();
      $relatedFeatureSlider.show();
      $featureSliderContainer.show();
    });

    $featureSlider.not('.slick-initialized').slick($defaultSliderSettings);
  };

  const initGradesSlider = () => {
    const $selectedGradeInput = $(checkedGradeInputSelector);
    const selectedTab = $selectedGradeInput.attr('id');
    const $selectedTabContainer = $selectedGradeInput.closest(tabContainerSelector);
    const $gradesContent = $selectedTabContainer.find(tabContentSelector);
    const $relatedGradesContent = $gradesContent.filter(`[${tabContentAttr}=${selectedTab}]`);
    $relatedGradesContent.show();
  };

  const setYearlySubscriptionPlan = () => {
    $planInput.each((index, plan) => {
       if ($(plan).attr(offerPeriodPlanAttr).toLowerCase() === PREFERRED_PLAN_PERIOD) {
         $(plan).prop('checked', true);
       } else {
         $(plan).prop('checked', false);
       }
    });
  };

  const setYearlyTab = () => {
    const $yearlyTab = $tab.filter(`[${tabPeriodAttr}=${PREFERRED_PLAN_PERIOD}]`);
    $yearlyTab.trigger('click');
  };

  initFeatureSlider();
  initGradesSlider();

  $(document)
    .on('change', featuresInputsSelector, () => {
      const $selectedFeatureInput = $(checkedFeatureInputSelector);
      const selectedTab = $selectedFeatureInput.attr('id');
      const $selectedTabContainer = $selectedFeatureInput.closest(tabContainerSelector);
      const $featuresContent = $selectedTabContainer.find(tabContentSelector);
      const $relatedFeatureContent = $featuresContent.filter(`[${tabContentAttr}=${selectedTab}]`);
      const $slider = $relatedFeatureContent.find('[data-selector="app-features-slider"]');

      $featuresContent.hide();
      $relatedFeatureContent.show();

      if (selectedTab === 'worksheets') {
        $slider.slick('unslick');
        $slider.not('.slick-initialized').slick($worksheetSliderSettings);
      } else {
        $slider.slick('refresh');
      }
    })
    .on('change', gradesInputsSelector, () => {
      const $selectedGradeInput = $(checkedGradeInputSelector);
      const selectedTab = $selectedGradeInput.attr('id');
      const $selectedTabContainer = $selectedGradeInput.closest(tabContainerSelector);
      const $gradesContent = $selectedTabContainer.find(tabContentSelector);
      const $relatedGradesContent = $gradesContent.filter(`[${tabContentAttr}=${selectedTab}]`);
      $gradesContent.hide();
      $relatedGradesContent.show();
    })
    .on('click', goToTopBtnSelector, () => scrollToTop())
    .on('click', goToCheckoutBtnSelector, () => {
      scrollToHashedElement(subscriptionPlansSliderHash, {scrollTimeout: 0});
      if ($subscriptionSliderSection.is(':hidden')) return;

      const yearlyPlan = $planInput.filter(`[${offerPeriodPlanAttr}=${PREFERRED_PLAN_PERIOD}]`);
      if (yearlyPlan.length <= 0) return;

      setYearlySubscriptionPlan();
      setYearlyTab();
      $goToCheckoutSliderBtn.trigger('click');
    });
});
