function onYoutubeReady() {
  const SvideoContainer = $('[data-selector="youtube-container"]');
  const $videoLoader = $('[data-selector="youtube-iframe-loader"]');

  const onPlayerReady = () => {
    $videoLoader.hide();
    SvideoContainer.css('backgroundColor', 'transparent');
    window.adjustVideo();
  };

  const createPlayer = (videoId) => {
    new window.YT.Player(`youtube-video-${videoId}`, {
      width: '100%',
      height: '300',
      videoId: videoId,
      events: {
        onReady: onPlayerReady
      }
    });
  };

  SvideoContainer.each((index, element) => {
    const videoId = $(element).attr('data-video-id');
    createPlayer(videoId);
  });
}

if (window.YT && window.YT.Player) {
  onYoutubeReady();
} else {
  window.onYouTubeIframeAPIReady = onYoutubeReady;
}
