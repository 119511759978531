import {
  braintreeDropinConfig,
  getError,
  isThreeDSecureCanceled,
  processingPlurals,
  threeDSecureConfig,
  toggleButtonLoading,
} from '../utils';
import {
  CURRENCY,
  CUSTOM_MESSAGE,
  DROPIN_SCRIPT_URL,
  ENDPOINT,
  SUBSCRIPTION_PERIOD_MAPPING,
  SUBSCRIPTION_PERIODS,
  MODALS
} from '../config';
import {
  handleDropinToken
} from '../payment/subscription.utils';


$(document).ready(() => {
  const $websitePlansModal = $(MODALS.WEBSITE_CR_TG_PLANS_MODAL);
  if (!$websitePlansModal.length) return;

  let isSuccessStepShown = false;

  const planBtn = '[data-selector="website-plans-modal-go-to-checkout-btn"]';

  const braintreeDropin = '#website-plans-cr-tg-dropin-container';
  let braintreeDropinInstance = {};

  const $plansContainer = $('[data-selector="website-plans-modal-plans"]');
  const $purchaseContainer = $('[data-selector="website-plans-modal-purchase"]');
  const backToPlansBtn = '[data-selector="website-plans-modal-back-btn"]';
  const $backToPlansBtn = $(backToPlansBtn);

  const $websitePlansModalTitle = $('[data-selector="free-website-plans-modal-title"]');

  const goToPaymentBtn = '[data-selector="go-to-payment-btn"]';
  const $goToPaymentBtn = $(goToPaymentBtn);
  const submitPaymentBtn = '[data-selector="website-plans-modal-pay-btn"]';
  const $submitPaymentBtn = $websitePlansModal.find(submitPaymentBtn);
  const $subscriptionError = $websitePlansModal.find('[data-selector="subscription-error"]');

  const $buySubscriptionSuccessStep = $('[data-selector="buy-subscription-success"]');
  const $subtitle = $('[data-selector="website-plans-modal-subtitle"]');
  const successModalTitle = 'Welcome to Kids Academy Premium!';
  const paymentStepTitle = 'Try Kids Academy Premium!';

  const trialDurationAttr = 'data-trial-duration';
  const priceAttr = 'data-price';
  const redirectAttr = 'data-redirect';
  const slugAttr = 'data-slug';
  const periodAttr = 'data-period';

  const planPeriodDisplayAttr = 'data-period-display';
  const successStepActive = '_success-step-active';

  const $planPeriod = $websitePlansModal.find('[data-selector="plan-period-value"]');
  const $fullPrice = $websitePlansModal.find('[data-selector="plan-full-price-value"]');
  const $trialValue = $websitePlansModal.find('[data-selector="plan-trial-period-value"]');
  const $trialRow = $websitePlansModal.find('[data-selector="plan-trial-row"]');
  const $todayPrice = $websitePlansModal.find('[data-selector="plan-total-price-value"]');
  const $renewCondition = $websitePlansModal.find('[data-selector="plan-renew-condition"]');

  const $plansModalLoading = $('[data-selector="website-plans-modal-loading"]');
  const $plansModalContent = $('[data-selector="website-plans-modal-content"]');

  const toggleWebsitePlansLoading = (isLoading) => {
    if (isLoading) {
      $plansModalLoading.show();
      $plansModalContent.css('height', '400px');
    } else {
      $plansModalLoading.hide();
      $plansModalContent.attr('style', '');
    }
  };

  const renderSecondStepUI = ($button) => {
    $plansContainer.hide();
    $purchaseContainer.show();
    $subtitle.hide();

    toggleButtonLoading($button, false);
    $websitePlansModalTitle.html(paymentStepTitle);

    const price = $button.attr(priceAttr);
    const trialDuration = parseInt($button.attr(trialDurationAttr));
    const planPeriodDisplay = $button.attr(planPeriodDisplayAttr);
    const planPeriod = $button.attr(periodAttr);

    const planPriceString = (price / 100).toFixed(2);
    /* eslint-disable max-len */
    const trialDurationString = processingPlurals(trialDuration, `${trialDuration} day`, `${trialDuration} days`);
    /* eslint-enable max-len */

    $planPeriod.html(planPeriodDisplay);
    $fullPrice.html(`${CURRENCY}${planPriceString}`);

    if (trialDuration === 0) {
      $trialRow.hide();
      $todayPrice.html(`${CURRENCY}${planPriceString}`);
    } else {
      $trialRow.show();
      $trialValue.html(trialDurationString);
      $todayPrice.html(`${CURRENCY}0.00`);

      if (planPeriod !== SUBSCRIPTION_PERIODS.LIFETIME) {
        const text = SUBSCRIPTION_PERIOD_MAPPING[planPeriod](planPriceString);
        $renewCondition.html(text);
      }
    }
  };

  const initBraintreeDropin = (totalPriceInCents, priceFor3DSecure, slug, redirect) => {
    if (braintree && $(braintreeDropin).length) {
      const totalPriceInDollars = (totalPriceInCents / 100).toFixed(2);
      const braintreeConf = braintreeDropinConfig(totalPriceInDollars, braintreeDropin);

      braintree.dropin.create(braintreeConf,
        function (createErr, instance) {
        braintreeDropinInstance = instance;
          if (createErr) {
            // An error in the create call is likely due to
            // incorrect configuration values or network issues.
            // An appropriate error will be shown in the UI.

            $subscriptionError.html(
              `${createErr.message}
               <br>${CUSTOM_MESSAGE.BRAINTREE_CREATE_ERROR}`
            );
            $subscriptionError.show();
            toggleButtonLoading($goToPaymentBtn, false);
            // eslint-disable-next-line no-console
            console.error('dropin create error: ', createErr);
            return;
          }

          toggleButtonLoading($backToPlansBtn, false);

          if (instance.isPaymentMethodRequestable()) {
            // This will be true if you generated the client token
            // with a customer ID and there is a saved payment method
            // available to tokenize with that customer.
            $submitPaymentBtn.show();
          }

          instance.on('paymentMethodRequestable', () => {
            $submitPaymentBtn.show();
            $subscriptionError.hide();
          });

          instance.on('noPaymentMethodRequestable', () => {
            $submitPaymentBtn.hide();
            $subscriptionError.hide();
          });

          instance.on('paymentOptionSelected', () => {
            $subscriptionError.hide();
            $submitPaymentBtn.show();
          });

          toggleButtonLoading($goToPaymentBtn, false);
          toggleButtonLoading($submitPaymentBtn, false);

          $submitPaymentBtn.click(() => {
            $backToPlansBtn.hide();
            $subscriptionError.hide();
            toggleButtonLoading($submitPaymentBtn, true);

            const priceFor3DSecureStr = (priceFor3DSecure / 100).toFixed(2).toString();
            const threeDSecureConf = threeDSecureConfig(priceFor3DSecureStr);

            instance.requestPaymentMethod({
              threeDSecure: threeDSecureConf
            }, function (requestPaymentMethodErr, payload) {
              if (requestPaymentMethodErr) {
                // eslint-disable-next-line no-console
                console.error('requestPaymentMethodErr: ', requestPaymentMethodErr);

                $subscriptionError.text(requestPaymentMethodErr.message);
                $subscriptionError.show();
                toggleButtonLoading($submitPaymentBtn, false);
                $backToPlansBtn.show();
                return;
              }

              if (isThreeDSecureCanceled(payload)) {
                instance.clearSelectedPaymentMethod();
                $subscriptionError.text(CUSTOM_MESSAGE.THREE_D_SECURE_ABORT);
                $subscriptionError.show();
                $backToPlansBtn.show();
                toggleButtonLoading($submitPaymentBtn, false);
                return;
              }

              $.ajax({
                url: ENDPOINT.BUY_SUBSCRIPTION,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify({
                  payment_method_nonce: payload.nonce,
                  plan_slug: slug,
                  source: 'website-offer'
                })
              })
                .then(() => {
                  $purchaseContainer.hide();
                  $websitePlansModalTitle.html(successModalTitle);
                  $(MODALS.WEBSITE_CR_TG_PLANS_MODAL).addClass(successStepActive);
                  $buySubscriptionSuccessStep.show();
                  isSuccessStepShown = true;
                }, (errorResp) => {
                  instance.clearSelectedPaymentMethod();
                  $subscriptionError.text('');
                  $subscriptionError.append(getError(errorResp));
                  $subscriptionError.show();
                  $backToPlansBtn.show();

                  toggleButtonLoading($submitPaymentBtn, false);
                });
            });
          });
        });
    }
  };

  const isTrialEnabled = ($button) => {
    if (!window.ableToGetTrial) return false;

    const trialDuration = $button.attr(trialDurationAttr);
    const isTrialDisabled = parseInt(trialDuration) === 0;
    if (isTrialDisabled) return false;

    return true;
  };

  const getTotalPriceInCents = ($button) => {
    const priceInCents = $button.attr(priceAttr);
    let totalPriceInCents = priceInCents;
    const priceFor3DSecure = priceInCents;

    if (isTrialEnabled($button)) totalPriceInCents = 0;

    return {totalPriceInCents, priceFor3DSecure};
  };

  const goToPaymentErrorHandler = ($button) => {
    toastr.error('Something went wrong, please contact us!', '', {timeOut: 3000});
    toggleButtonLoading($button, false);
  };

  const dropinTokenCallback = (planPrice, planSlug, $button) => {
    if (!window.braintree) {
      goToPaymentErrorHandler($button);
      return;
    }

    const redirect = $button.attr(redirectAttr);
    renderSecondStepUI($button);

    const {totalPriceInCents, priceFor3DSecure} = getTotalPriceInCents($button);
    initBraintreeDropin(totalPriceInCents, priceFor3DSecure, planSlug, redirect);

    toggleWebsitePlansLoading(false);
  };

  $(document)
    .on('click', backToPlansBtn, () => {
      $plansContainer.show();
      $submitPaymentBtn.hide();
      $purchaseContainer.hide();
      $subscriptionError.hide();

      braintreeDropinInstance.teardown((err) => {
        // eslint-disable-next-line no-console
        if (err) { console.error('An error occurred during teardown:', err); }
        $(braintreeDropin).empty();
      });
    })
    .on('click', planBtn, (e) => {
      const $button = $(e.target);
      const planPrice = $button.attr(priceAttr);
      const planSlug = $button.attr(slugAttr);

      toggleButtonLoading($button, true);
      toggleButtonLoading($backToPlansBtn, true);

      $.getScript(
        DROPIN_SCRIPT_URL,
        // eslint-disable-next-line max-len
        handleDropinToken(
          () => {dropinTokenCallback(planPrice, planSlug, $button);},
          goToPaymentErrorHandler
        )
      );
    });

    $(MODALS.WEBSITE_CR_TG_PLANS_MODAL).on('hidden.bs.modal', () => {
      if (isSuccessStepShown) document.location.reload();
    });
});
